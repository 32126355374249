import * as React from "react"
import {StudiengangsInfoFieldsItem} from '../items/StudiengangInfoItem';
import { getaccesToken,  getApplicationGuid, FinalizeBewerbung, getcheckfristzeit } from '../DataAccess'
import { isUndefined } from 'util'
import { LandingPageFieldsItem, LandingPageErrorsItem } from '../items/LandingPageItem'
import { HeaderLogo } from '../component/HeaderLogo'
import {HeaderStudiengangInfoField } from '../component/HeaderStudiengangInfo';
import { RowCategoryStatusField } from '../component/CategoryStatusField'
import { RouteComponentProps } from "react-router-dom"
import { LoadingAnimation } from '../component/loadingComponent'
import { SaveErrorRequest } from '../component/SaveErrorRequest';
import Footer from '../component/footer'

interface State {
    fields:
    {
        landingpage: LandingPageFieldsItem,
        studienganginfo : StudiengangsInfoFieldsItem
    },
    errors:
    {
        landingpage: LandingPageErrorsItem,
        submiterror: boolean
    },
    disabled: boolean, submitted: boolean, accessToken: string, ready: boolean, timeout: boolean
}

export default class LandingPage extends React.Component<RouteComponentProps<any>, State> {

    constructor(props: any) {
        super(props)

        this.state = {
            fields: {
                landingpage: {
                    stgGuid: '', ID: 0, Phase1: false, Phase2: false, Phase3: true, comment : '', confirm: false, StudiengangID:0, Fristzeit: '', semester : ''
                },
                studienganginfo: {
                    ID : 0, Studiengangsartbezeichnung:'', Studiengangsbezeichnung:'',Studiengangsortbezeichnung:'',GuideLink:''
                }

            },
            errors: {
                landingpage: {
                    stgGuid: '', ID: 0, Phase1: false, Phase2: false, Phase3: false
                },
                submiterror: false
            }, disabled: false, submitted: false, accessToken: '-1', ready: false, timeout: false
        }



        setTimeout(() => this.setState({ timeout: true }), 30000);
        let guid = this.getGuid();
        if ((guid === "" || guid === null)) {
            this.props.history.push('/');
        }
        this.checkGuid(guid);
    }

    locationError() {
        this.props.history.push({
            pathname: '/error'
        })
    }



    getGuid(): string {
        let data = sessionStorage.getItem('applicationguid');
        return data || "";

        // let search = this.getUrlParams();
        // return search.get("applicationguid") || "";
    }


getStgID(): string {
        let data = sessionStorage.getItem('StgID');
        return data  || "";

        // let search = this.getUrlParams();
        // return search.get("applicationguid") || "";
    }
    getUrlParams(): URLSearchParams {
        if (!this.props.location.search) return new URLSearchParams();
        return new URLSearchParams(this.props.location.search);
    }

    checkfristzeit = async (accessToken: string, studiengangID: number, semester: string) => {
        await getcheckfristzeit(accessToken, studiengangID, semester)
        .then(res => {
                if (res !== null) {

                    let fristzeititem = JSON.parse(JSON.stringify(res));
                    if (fristzeititem !== null) {
                        if (fristzeititem.IsFristzeitOk as boolean === true) {
                            return true;
                        }
                        else {
                            this.props.history.push('/expired');
                            return false;
                        }
                    } else {
                        this.props.history.push('/error');
                        return false;
                    }
                } else {
                    this.props.history.push('/error');
                    return false;
                }
            }
            )
    }

    checkGuid(guid: string) {
        getaccesToken()
        .then(res => {
                if (res !== null && res !== isUndefined && typeof res === "string") {
                    this.setState({ accessToken: res.toString() });
                    sessionStorage.setItem('token', res.toString());
                }
 
                getApplicationGuid(this.state.accessToken, guid)
                    .then(res => {
                        let TTPBewerberItem = JSON.parse(JSON.stringify(res));
                        let fields = this.state.fields;

                        if((TTPBewerberItem.StrGuid !== null &&  typeof TTPBewerberItem.StrGuid === "string" &&  TTPBewerberItem.StrGuid !== '') === false)
                        {
                            window.location.assign('https://www.fh-krems.ac.at/en/international-focus/transnational-short-programmes-and-projects/');
                        }

                        fields.landingpage.stgGuid = TTPBewerberItem.StrGuid;

                        fields.landingpage.StudiengangID = TTPBewerberItem.StudiengangID as number;
                        fields.landingpage.semester = TTPBewerberItem.semester;
                        sessionStorage.setItem('StgID',TTPBewerberItem.StudiengangID);


                       

                        if (fields.landingpage.stgGuid !== guid) {
                            window.location.assign('https://www.fh-krems.ac.at/en/international-focus/transnational-short-programmes-and-projects/');
                        }else


                        if (TTPBewerberItem.StatusAktiv as boolean === false && TTPBewerberItem.ActiveNachreichung as boolean === true) {
                            this.props.history.push('/subsequent');
                        }else
                        if (TTPBewerberItem.isAbgeschlossen as boolean === true) {
                            this.props.history.push('/completed');
                        }else 

                        if (TTPBewerberItem.IsFristzeitOk as boolean === false) {
                            this.props.history.push('/expired');
                        }else 
                        
                    
                        // if (this.checkfristzeit(this.state.accessToken, Number(fields.landingpage.StudiengangID), TTPBewerberItem.semester))
                        // {

                        // }

                        if (TTPBewerberItem.Phase1 as boolean === false) {
                            this.props.history.push('/personalData');
                        }else 
                        {

                        fields.landingpage.Phase1 = TTPBewerberItem.Phase1 as boolean;
                        fields.landingpage.Phase2 = TTPBewerberItem.Phase2 as boolean;
                        fields.landingpage.Phase3 = TTPBewerberItem.Phase3 as boolean;
                        fields.landingpage.StudiengangID = TTPBewerberItem.StudiengangID as number;

                        if(TTPBewerberItem.Fristzeit !== null)fields.landingpage.Fristzeit = TTPBewerberItem.Fristzeit.replaceAll('.','/');

                        

                        fields.studienganginfo.ID = TTPBewerberItem.StudiengangID as number;
                        fields.studienganginfo.Studiengangsartbezeichnung = TTPBewerberItem.Studiengangsartbezeichnung;
                        fields.studienganginfo.Studiengangsbezeichnung = TTPBewerberItem.Studiengangsbezeichnung;
                        fields.studienganginfo.Studiengangsortbezeichnung = TTPBewerberItem.Studiengangsortbezeichnung;
                        // this.props.history.push({
                        //   pathname: '/',
                        //   search: 'applicationguid=test',
                        //   state: { accessToken: this.state.accessToken }
                        // })

                        this.setState({ fields, ready: true });
                        }
                    }
                    );
                   
            })
    }

    handleInputChange = (event : React.ChangeEvent<HTMLTextAreaElement>) => {

        let fields = this.state.fields;
        fields.landingpage["comment"] = event.target.value;
        this.setState({ fields });
    }

    onInputCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {

        let fields = this.state.fields;
        fields.landingpage.confirm = event.target.checked;
        this.setState({ fields });

    }

    clickhandler = async (event: any) => {
        event.preventDefault();
    this.setState({ disabled: true })
    let errors = this.state.errors;
    errors["submiterror"] = false;
    this.setState({ errors });

  
      if (this.state.accessToken !== "-1" && this.state.accessToken !== null) {

        
        let json = JSON.stringify(this.state.fields.landingpage);
        
        const response = await FinalizeBewerbung(this.state.accessToken, json);

        if (response === 200) {
            sessionStorage.clear();
            sessionStorage.setItem('StgID',this.state.fields.landingpage.StudiengangID.toString());
            this.props.history.push({
                pathname: '/successfullapplied'
            })
        } else {
          this.setState({ disabled: false })
          let errors = this.state.errors;
          errors["submiterror"] = true;
          this.setState({ errors });
        }
      }
    }

    render() {
        if (this.state.timeout === true && !this.state.ready) this.locationError();
        if (!this.state.ready)
            return <LoadingAnimation />;
        return (
            <div id="visual-portal-wrapper" className="clearfix">
                <HeaderLogo />

                <div id="portal-columns" className="row">
                    <div id="portal-columns-content" className="container">
                        <h1>IMC Krems online application system</h1>
                        <HeaderStudiengangInfoField StudiengangsArtbezeichnung={this.state.fields.studienganginfo.Studiengangsartbezeichnung} StudiengangsOrtbezeichnung={this.state.fields.studienganginfo.Studiengangsortbezeichnung} Studiengangsbezeichnung={this.state.fields.studienganginfo.Studiengangsbezeichnung} 
                    ></HeaderStudiengangInfoField>
                        <p className="description">Your Online application at a glance</p>
                        <p className="discreet">
                            When pressing the "Continue" or "Save and continue later" button, all the details you have already entered are automatically saved. <br></br><br></br>
                            You can continue your online application for the {this.state.fields.studienganginfo.Studiengangsartbezeichnung} {this.state.fields.studienganginfo.Studiengangsbezeichnung} in {this.state.fields.studienganginfo.Studiengangsortbezeichnung} at a later time by entering your personal access link.<br></br>
                            At the current stage, <b>your online application is not completed</b>.<br></br><br></br>
                            <h4>Please consider: </h4>
                            <ul className="discreet">
        <li>the <b> application deadline on {this.state.fields.landingpage.Fristzeit}</b> when you proceed and complete your online application</li>
                            <li>only complete online applications submitted before the application deadline will be accepted</li>
                            <li>once you have submitted your completed online application you will not be able to make any further changes</li>
                            </ul>
                        </p>
                        <fieldset>
                            <table id="status" className="">
                                <thead>
                                    <tr className="header">
                                        <th>Category</th>
                                        <th>Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <RowCategoryStatusField BooleanValuePhase={this.state.fields.landingpage.Phase1} accessToken={this.state.accessToken} pathname='/personalData' guid={this.state.fields.landingpage.stgGuid}
                                        titel='Personal Data' disabled={false} ></RowCategoryStatusField>
                                    <RowCategoryStatusField BooleanValuePhase={this.state.fields.landingpage.Phase2} accessToken={this.state.accessToken} pathname='/qualifications' guid={this.state.fields.landingpage.stgGuid}
                                        titel='Qualifications' disabled={this.state.fields.landingpage.Phase1 === false} ></RowCategoryStatusField>
                                    <RowCategoryStatusField BooleanValuePhase={this.state.fields.landingpage.Phase3} accessToken={this.state.accessToken} pathname='/documents' guid={this.state.fields.landingpage.stgGuid}
                                        titel='Document Upload' disabled={this.state.fields.landingpage.Phase2 === false}></RowCategoryStatusField>
                                </tbody>
                            </table>
                        </fieldset>
                        {
                            (this.state.fields.landingpage.Phase1 === true && this.state.fields.landingpage.Phase2 === true && this.state.fields.landingpage.Phase3 === true) &&
                            <div>
                            <fieldset>
                                <legend>
                                <span id="Kommentarlabel"> My comments</span>
                                </legend>
                                <p className="discreet">
											<span id="KommentarSubhLabel"> Use the comments field to provide us with additional information:</span>
										</p>
                                        <textarea rows={ 2} cols={20} value={this.state.fields.landingpage.comment} id='BewerberKommentar' onChange={(e) => this.handleInputChange(e)}></textarea>
                                        
                            </fieldset>
                            <fieldset id="agreement">
										<legend>
											<span id="BestaetigtLabelTitle"> Confirm</span>
										</legend>
										<ol className="form">
											<li className="w-100">
												<span id="Best_Erkl_Label" className="discreet"> Please note that once you have submitted your completed online application, you will not be able to make any further changes.</span>
											</li>
											<li className="w-100">
												<div className="label-wrapper no-span large-text no-margin mt-1 dark">
													<label htmlFor={"agree"}>
														<span id="Best_Label1"> I confirm that the information I have provided is correct, and I acknowledge that only fully completed applications will be processed. </span>
														<em title="Required" className="fieldRequired">*</em>
													</label>
                                                    <input type="checkbox" id="agree" onChange={this.onInputCheckboxChange} checked={this.state.fields.landingpage.confirm} />
													{/* <input id="agree" type="checkbox" name="agree" onclick="javascript:setTimeout('__doPostBack(\'agree\',\'\')', 0)"> */}
												</div>
											</li>
											
											
										</ol>
									</fieldset>
                           
                            </div>
                        }
{this.state.fields.landingpage.confirm && 

<div className="formControls clearfix">
{this.state.errors["submiterror"] && <SaveErrorRequest />}
                            <button type="submit" className="button orange right" disabled={this.state.disabled} onClick={this.clickhandler}>Submit application</button><br /><br />
</div>
}

                    </div>
                </div>
                <Footer />
            </div>
        )
    }
}

