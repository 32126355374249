import * as React from 'react'
import { Component } from 'react'
import { HeaderLogo } from '../component/HeaderLogo'
import Footer from '../component/footer'

export default class ErrorConnection extends Component<[], []>
{
    render() {
    return (
        <div id="visual-portal-wrapper" className="clearfix">
        <HeaderLogo/>
     
        <div id="portal-columns" className="row">
            <div id="portal-columns-content" className="container">
                <h1>IMC Krems online application system</h1>
                <p className="description">
                <br ></br>
                <span style={{color: "red"}}>
                <strong>Error!</strong> The data connection with our server has been interrupted.  <br></br>
                Unfortunately, there is a connectivity problem with the server. Please try again later.  
                <br></br><br></br>If the problem should remain after several attempts, please contact IMC International Relations Department on  <a href="mailto:admission@fh-krems.ac.at">admission@fh-krems.ac.at</a>!
                </span>
                </p>
                
            </div>
        </div>
        <Footer />
    </div>
       
    );
    }
}