import * as React from "react";

export class SaveErrorRequest  extends React.Component<{}, {}>{
   
    render() {
        
            return (
                
                <div className="alert alert-error">
                <br ></br>
                <span style={{color: "red"}}>
                <strong>Error!</strong> The data connection with our server has been interrupted.  <br></br>
                Unfortunately, there is a connectivity problem with the server. Please try again later.  
                <br></br><br></br>If the problem should remain after several attempts, please contact IMC International Relations Department on  <a href="mailto:admission@fh-krems.ac.at">admission@fh-krems.ac.at</a>!
                </span>
                </div>
            );
       
    }
}