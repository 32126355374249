import * as React from 'react'
import { Component } from 'react'
import { HeaderLogo } from '../component/HeaderLogo'
import Footer from '../component/footer'

export default class CompletedInfo extends Component<[], []>
{
    render() {
    return (
        <div id="visual-portal-wrapper" className="clearfix">
        <HeaderLogo/>
     
        <div id="portal-columns" className="row">
            <div id="portal-columns-content" className="container">
                <h1>IMC Krems online application system</h1>
                <p className="description">       
                <strong>Your application is already completed!</strong></p>
                <p className="discreet">TIf you have any questions, please contact the IMC International Relations Department on <a href="mailto:admission@fh-krems.ac.at">admission@fh-krems.ac.at</a></p>
                
            </div>
        </div>
        <Footer />
    </div>
        
    );
    }
}